document.addEventListener("turbo:load", initializeNav);

function initializeNav() {
  const hamburgerButton = document.querySelector(
    ".c-header__hamburger[aria-controls]"
  );
  const html = document.querySelector("html");

  function closeMobileMenu() {
    html.classList.remove("is-menu-open");
    if (hamburgerButton) {
      hamburgerButton.setAttribute("aria-expanded", "false");
    }
  }

  if (hamburgerButton) {
    hamburgerButton.addEventListener("click", function (event) {
      event.preventDefault();
      html.classList.toggle("is-menu-open");
      const expanded = hamburgerButton.getAttribute("aria-expanded") === "true";
      hamburgerButton.setAttribute("aria-expanded", !expanded);
    });
  }

  document.querySelectorAll(".c-header__menu-item a").forEach((anchor) => {
    anchor.addEventListener("click", function () {
      if (html.classList.contains("is-menu-open")) {
        closeMobileMenu();
      }
    });
  });

  const residentPortalLink = document.querySelector(
    ".c-header__menu-item a#resident_portal_link"
  );
  if (residentPortalLink) {
    residentPortalLink.addEventListener("click", function (event) {
      event.preventDefault();
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Resident Portal",
          access_point: "Header",
        });
      }
      window.location = this.href;
    });
  }
}
