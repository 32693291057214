import { displayErrorMessages } from "./displayErrorMessages";
export function validateAllCheckboxContainers() {
  let allContainersValid = true;
  document.querySelectorAll(".county-container").forEach((container) => {
    if (!validateCheckboxContainer(container)) {
      allContainersValid = false;
    }
  });
  return allContainersValid;
}

function validateCheckboxContainer(container) {
  let isServiceChecked = Array.from(
    container.querySelectorAll(".service-type-checkbox")
  ).some((checkbox) => checkbox.checked);
  let isCountyChecked = Array.from(
    container.querySelectorAll(".county-checkbox")
  ).some((checkbox) => checkbox.checked);
  let hasCounties = container.querySelectorAll(".county-checkbox").length > 0;

  displayErrorMessages(
    container,
    isServiceChecked,
    isCountyChecked,
    hasCounties
  );
  return isServiceChecked && (!hasCounties || isCountyChecked);
}
