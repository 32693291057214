export function initializeValidation(containerId) {
  const container = document.querySelector(containerId);
  if (!container) return;

  const serviceCheckboxes = container.querySelectorAll(
    ".service-type-checkbox"
  );
  const countyCheckboxes = container.querySelectorAll(".county-checkbox");
  const serviceErrorContainer = container.querySelector(
    ".services-error-container"
  );
  const countyErrorContainer = container.querySelector(
    ".county-error-container"
  );
  const form = document.getElementById("vendor_contact_form");

  form.addEventListener("submit", function (event) {
    let isServiceChecked = Array.from(serviceCheckboxes).some(
      (checkbox) => checkbox.checked
    );
    let isCountyChecked = Array.from(countyCheckboxes).some(
      (checkbox) => checkbox.checked
    );

    if (!isServiceChecked || !isCountyChecked) {
      event.preventDefault();
      if (!isServiceChecked) {
        serviceErrorContainer.textContent =
          "Please select at least one service and indicate if these services are offered for vacant properties, occupied properties or both.";
        serviceErrorContainer.style.color = "rgb(220, 53, 69)";
        serviceCheckboxes.forEach((checkbox) => {
          checkbox.closest(".form-check").style.color = "rgb(220, 53, 69)";
        });
      } else {
        serviceErrorContainer.textContent = "";
        serviceCheckboxes.forEach((checkbox) => {
          checkbox.closest(".form-check").style.color = "";
        });
      }
      if (!isCountyChecked) {
        countyErrorContainer.textContent = "Please select at least one county.";
        countyErrorContainer.style.color = "rgb(220, 53, 69)";
        countyCheckboxes.forEach((checkbox) => {
          checkbox.closest(".form-check-county").style.color =
            "rgb(220, 53, 69)";
        });
      } else {
        countyErrorContainer.textContent = "";
        countyCheckboxes.forEach((checkbox) => {
          checkbox.closest(".form-check-county").style.color = "";
        });
      }
    }
  });

  serviceCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", () => resetStyles("services"));
  });

  countyCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", () => resetStyles("counties"));
  });

  function resetStyles(type) {
    if (type === "services") {
      serviceErrorContainer.textContent = "";
      serviceCheckboxes.forEach((checkbox) => {
        checkbox.closest(".form-check").style.color = "";
      });
    }
    if (type === "counties") {
      countyErrorContainer.textContent = "";
      countyCheckboxes.forEach((checkbox) => {
        checkbox.closest(".form-check-county").style.color = "";
      });
    }
  }
}
