document.addEventListener("turbo:load", function () {
  let autocompleteData = null;
  let dataFetched = false;

  const autocompleteInputs = document.querySelectorAll(".autocomplete-input");

  autocompleteInputs.forEach((inputElement) => {
    inputElement.dataset.originalPlaceholder = "Address, City, State or Zip";
    inputElement.placeholder = "Loading properties...";
    inputElement.disabled = true;
  });

  function fetchAutocompleteData() {
    if (dataFetched) {
      return Promise.resolve(autocompleteData);
    } else {
      dataFetched = true;
      return fetch("/api/autosearch")
        .then((response) => response.json())
        .then((data) => {
          autocompleteData = data;

          autocompleteInputs.forEach((inputElement) => {
            inputElement.placeholder = inputElement.dataset.originalPlaceholder;
            inputElement.disabled = false;
          });

          return data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);

          autocompleteInputs.forEach((inputElement) => {
            inputElement.placeholder = "Error loading data";
            inputElement.disabled = false;
          });

          return null;
        });
    }
  }

  fetchAutocompleteData();

  function initializeAutocomplete(inputElement) {
    const container = inputElement.parentElement;
    const clearButton = container.querySelector(".clear-search");
    const suggestionsList = container.querySelector(".suggestion-list");
    const filterButton = document.querySelector(".filter-link");

    if (!clearButton || !suggestionsList) {
      return;
    }

    function toggleClearButton() {
      if (inputElement.value.length >= 3) {
        clearButton.classList.remove("d-none");
      } else {
        clearButton.classList.add("d-none");
      }
    }

    toggleClearButton();

    inputElement.addEventListener("input", function () {
      toggleClearButton();
      const query = inputElement.value.toLowerCase();
      suggestionsList.innerHTML = "";

      if (query.length < 3) {
        return;
      }

      if (!autocompleteData) {
        displayErrorSuggestion();
        return;
      }

      displaySuggestions(autocompleteData, query);
    });

    if (filterButton) {
      filterButton.addEventListener("click", function () {
        suggestionsList.innerHTML = "";
      });
    }

    function displayErrorSuggestion() {
      suggestionsList.innerHTML = "";
      const errorItem = document.createElement("li");
      errorItem.classList.add("suggestion-item");

      const iconDiv = document.createElement("div");
      iconDiv.classList.add("icon");

      iconDiv.innerHTML = `
        <svg class="me-3" width="18" height="18" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" stroke="#dc3545" stroke-width="2" fill="none"></circle>
          <line x1="5" y1="5" x2="19" y2="19" stroke="#dc3545" stroke-width="2"></line>
        </svg>
      `;

      const textDiv = document.createElement("div");
      textDiv.innerHTML = "Error loading data. Please try again later.";
      textDiv.classList.add("text-content");

      errorItem.appendChild(iconDiv);
      errorItem.appendChild(textDiv);

      suggestionsList.appendChild(errorItem);
    }

    function displaySuggestions(data, query) {
      const { cities, properties, metros, states } = data;

      const filteredStates = states.filter((state) =>
        state.name?.toLowerCase().includes(query)
      );
      const filteredMetros = metros.filter((metro) =>
        metro.name?.toLowerCase().includes(query)
      );
      const filteredCities = cities.filter((city) =>
        city.name?.toLowerCase().includes(query)
      );
      const filteredProperties = properties.filter((property) =>
        property.name?.toLowerCase().includes(query)
      );

      suggestionsList.innerHTML = "";

      if (
        filteredStates.length === 0 &&
        filteredMetros.length === 0 &&
        filteredCities.length === 0 &&
        filteredProperties.length === 0
      ) {
        const noResultsItem = document.createElement("li");
        noResultsItem.classList.add("suggestion-item");

        const iconDiv = document.createElement("div");
        iconDiv.classList.add("icon");

        iconDiv.innerHTML = `
          <svg class="me-3" width="18" height="18" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="10" stroke="#dc3545" stroke-width="2" fill="none"></circle>
            <line x1="5" y1="5" x2="19" y2="19" stroke="#dc3545" stroke-width="2"></line>
          </svg>
        `;

        const textDiv = document.createElement("div");
        textDiv.innerHTML = "Nothing found";
        textDiv.classList.add("text-content");

        noResultsItem.appendChild(iconDiv);
        noResultsItem.appendChild(textDiv);
        noResultsItem.addEventListener("click", function () {
          inputElement.value = "";
          inputElement.focus();
          suggestionsList.innerHTML = "";
          toggleClearButton();
        });

        suggestionsList.appendChild(noResultsItem);
        return;
      }

      if (filteredStates.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("States"));
        filteredStates.forEach((state) => {
          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-state"></use></svg>',
              state.name,
              state.url,
              query,
              "state",
              state.id
            )
          );
        });
      }

      if (filteredMetros.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("Metros"));
        filteredMetros.forEach((metro) => {
          const metroUrl = metro.url;

          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-metro"></use></svg>',
              metro.name,
              metroUrl,
              query,
              "metro",
              metro.id
            )
          );
        });
      }

      if (filteredCities.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("Cities"));
        filteredCities.forEach((city) => {
          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-city"></use></svg>',
              city.name,
              city.url,
              query,
              "city",
              city.id
            )
          );
        });
      }

      if (filteredProperties.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("Properties"));
        filteredProperties.forEach((property) => {
          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-market"></use></svg>',
              property.name,
              property.url,
              query,
              "property",
              property.id
            )
          );
        });
      }
    }

    clearButton.addEventListener("click", function () {
      inputElement.value = "";
      toggleClearButton();
      inputElement.focus();
      suggestionsList.innerHTML = "";
    });

    function createSuggestionItem(icon, name, url, query, type, id) {
      const suggestionItem = document.createElement("li");
      const iconDiv = document.createElement("div");
      iconDiv.classList.add("icon");
      iconDiv.innerHTML = icon;
      const textDiv = document.createElement("div");
      const highlightedName = name.replace(
        new RegExp(query, "gi"),
        (match) => `<span class="highlight">${match}</span>`
      );
      textDiv.innerHTML = highlightedName;
      textDiv.classList.add("text-content");
      suggestionItem.appendChild(iconDiv);
      suggestionItem.appendChild(textDiv);
      suggestionItem.classList.add("suggestion-item");
      suggestionItem.dataset.url = url;
      suggestionItem.dataset.type = type;
      suggestionItem.dataset.id = id;

      suggestionItem.tabIndex = 0;

      suggestionItem.addEventListener("click", function (e) {
        e.preventDefault();
        handleSuggestionSelection();
      });

      suggestionItem.addEventListener("keydown", function (e) {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          handleSuggestionSelection();
        }
      });

      function handleSuggestionSelection() {
        inputElement.value = name;
        suggestionsList.innerHTML = "";

        if (type === "metro") {
          const marketSlug = url.split("/metro/")[1];
          const marketInput = document.querySelector("input[name='market']");
          if (marketInput) {
            marketInput.value = marketSlug;
          }

          window.location.href = `/search?market=${encodeURIComponent(
            marketSlug
          )}`;
        } else {
          window.location.href = url;
        }
      }

      return suggestionItem;
    }

    function createSuggestionHeader(text) {
      const header = document.createElement("li");
      header.textContent = text;
      header.classList.add("suggestion-header");
      return header;
    }
  }

  autocompleteInputs.forEach((inputElement) => {
    initializeAutocomplete(inputElement);
  });
});
