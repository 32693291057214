import $ from "jquery";
import "jquery-validation";
import "jquery-validation/dist/additional-methods.js";
import { validateAllCheckboxContainers } from "./validateCheckboxes";
import { trimFormInputs } from "./trimFormInput";

export function initializeJQueryValidation() {
  $("#vendor_contact_form").validate({
    submitHandler: (form, event) => {
      trimFormInputs();
      if (!validateAllCheckboxContainers()) {
        event.preventDefault();
        return false;
      }
      form.submit();
    },
    rules: {
      "vendor_contact[name]": {
        required: true,
        minlength: 3,
      },
      "vendor_contact[market_id]": {
        required: true,
      },
      "vendor_contact[market_capacity]": {
        required: true,
        digits: true,
        min: 1,
        integer: true,
      },
      "vendor_contact[first_name]": {
        required: true,
        minlength: 2,
        lettersonly: true,
      },
      "vendor_contact[last_name]": {
        required: true,
        minlength: 3,
        lettersonly: true,
      },
      "vendor_contact[email]": {
        required: true,
        customEmail: true,
      },
      "vendor_contact[phone]": {
        required: true,
        phoneUS: true,
      },
      "vendor_contact[poc_first_name]": {
        required: false,
        minlength: 3,
        lettersonly: true,
      },
      "vendor_contact[poc_last_name]": {
        required: false,
        minlength: 3,
        lettersonly: true,
      },
      "vendor_contact[poc_email]": {
        required: false,
        customEmail: true,
      },
      "vendor_contact[poc_phone]": {
        required: false,
        phoneUS: true,
      },
      "vendor_contact[street_address]": {
        required: true,
        minlength: 3,
      },
      "vendor_contact[city]": {
        required: true,
        lettersonly: true,
      },
      "vendor_contact[zip_code]": {
        required: true,
        zipcodeUS: true,
      },
      "vendor_contact[website]": {
        customUrl: true,
        required: false,
      },
      "vendor_contact[ownership_diversity]": {
        required: true,
      },
      "vendor_contact[certifications][]": {
        required: true,
      },
      "vendor_contact[trade_category]": {
        required: true,
      },
      "vendor_contact[insurance_requirements]": {
        required: true,
      },
    },
    messages: {
      "vendor_contact[name]": {
        required: "Company Name is required",
        minlength: "Company Name must be at least 3 characters long",
      },
      "vendor_contact[market_id]": {
        required: "Main Metropolitan Area of service is required",
      },
      "vendor_contact[market_capacity]": {
        required: "Metro Capacity is required",
        digits: "Please enter only digits",
        min: "Metro Capacity must be at least 1",
        integer: "Metro Capacity must be a whole number",
      },
      "vendor_contact[first_name]": {
        required: "First name is required",
        lettersonly: "First name should only contain letters",
      },
      "vendor_contact[last_name]": {
        required: "Last name is required",
        lettersonly: "Last name should only contain letters",
      },
      "vendor_contact[email]": {
        required: "Email is required",
        customEmail: "Please enter a valid email address",
      },
      "vendor_contact[phone]": {
        required: "Phone number is required",
        phoneUS: "Please enter a valid 10-digit US phone number",
      },
      "vendor_contact[poc_first_name]": {
        required: "Point of Contact first name is required",
        minlength:
          "Point of Contact first name must be at least 3 characters long",
        lettersonly: "Point of Contact first name should only contain letters",
      },
      "vendor_contact[poc_last_name]": {
        required: "Point of Contact last name is required",
        minlength:
          "Point of Contact last name must be at least 3 characters long",
        lettersonly: "Point of Contact last name should only contain letters",
      },
      "vendor_contact[poc_email]": {
        required: "Point of Contact email is required",
        customEmail: "Please enter a valid email address",
      },
      "vendor_contact[poc_phone]": {
        required: "Point of Contact phone number is required",
        phoneUS: "Please enter a valid 10-digit US phone number",
      },
      "vendor_contact[street_address]": {
        required: "Street Address is required",
        minlength: "Street Address must be at least 3 characters long",
      },
      "vendor_contact[city]": {
        required: "City is required",
        lettersonly: "City should only contain letters",
      },
      "vendor_contact[zip_code]": {
        required: "ZIP Code is required",
        zipcodeUS: "Please enter a valid US ZIP Code",
      },
      "vendor_contact[website]": {
        customUrl: "Please enter a valid URL",
      },
      "vendor_contact[ownership_diversity]": {
        required: "Please select an option",
      },
      "vendor_contact[certifications][]": {
        required: "Please select at least one certification",
      },
      "vendor_contact[trade_category]": {
        required: "Trade category is required",
      },
      "vendor_contact[insurance_requirements]": {
        required:
          "You must select one of the options regarding insurance requirements",
      },
    },
    errorElement: "div",
    errorClass: "invalid-feedback",

    highlight: (element, errorClass, validClass) => {
      if ($(element).is(":checkbox") || $(element).is(":radio")) {
        const groupName = $(element).attr("name");
        if ($(element).is(":checked")) {
          $(element).addClass("is-valid").removeClass("is-invalid");
          $(`input[name='${groupName}']:not(:checked)`)
            .addClass("is-invalid")
            .removeClass("is-valid");
        } else {
          $(`input[name='${groupName}']`)
            .addClass("is-invalid")
            .removeClass("is-valid");
        }
      } else {
        if ($(element).val()) {
          $(element).addClass("is-invalid").removeClass("is-valid");
        }
      }
    },

    unhighlight: (element, errorClass, validClass) => {
      if ($(element).is(":checkbox") || $(element).is(":radio")) {
        const groupName = $(element).attr("name");
        if ($(`input[name='${groupName}']:checked`).length > 0) {
          $(`input[name='${groupName}']`)
            .addClass("is-valid")
            .removeClass("is-invalid");
        } else {
          $(`input[name='${groupName}']`)
            .removeClass("is-valid")
            .removeClass("is-invalid");
        }
      } else {
        $(element).removeClass("is-invalid");
        if ($(element).val()) {
          $(element).addClass("is-valid");
        } else {
          $(element).removeClass("is-valid");
        }
      }
    },

    errorPlacement: (error, element) => {
      if (element.attr("name") === "vendor_contact[certifications][]") {
        error.appendTo(element.closest(".mb-3"));
      } else if (
        element.attr("name") === "vendor_contact[ownership_diversity]"
      ) {
        error.appendTo(".ownership-diversity-error-container");
      } else if (
        element.attr("name") === "vendor_contact[insurance_requirements]"
      ) {
        error.appendTo(".insurance-requirements-error-container");
      } else if (element.hasClass("form-control-shadow")) {
        error.insertAfter(element);
      } else if (element.hasClass("form-select")) {
        error.insertAfter(element.next(".form-select"));
      } else {
        error.insertAfter(element);
      }
    },
  });

  $.validator.addMethod(
    "lettersonly",
    function (value, element) {
      return this.optional(element) || /^[^\d]+$/i.test(value);
    },
    "Only letters, spaces, and hyphens are allowed"
  );

  $.validator.addMethod(
    "phoneUS",
    function (value, element) {
      const cleaned = value.replace(/\D/g, "");

      if (cleaned.length === 0) {
        return true;
      }

      if (cleaned.length !== 10) {
        return false;
      }
      if (!/^1?[2-9]\d{2}/.test(cleaned)) {
        return false;
      }
      return true;
    },
    "Please enter a valid 10-digit US phone number."
  );
  $.validator.addMethod(
    "customEmail",
    function (value, element) {
      return (
        this.optional(element) ||
        (/^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value) &&
          /@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value))
      );
    },
    "Please enter a valid email address"
  );

  $.validator.addMethod(
    "customUrl",
    function (value, element) {
      return (
        this.optional(element) ||
        /^(https?:\/\/)?(www\.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(
          value
        )
      );
    },
    "Please enter a valid URL"
  );

  $.validator.addMethod(
    "integer",
    function (value, element) {
      return this.optional(element) || /^\d+$/.test(value);
    },
    "Metro Capacity must be a whole number"
  );

  $("input[type='checkbox'], input[type='radio']").on("change", function () {
    const groupName = $(this).attr("name");
    $(`input[name='${groupName}']`)
      .removeClass("is-valid")
      .removeClass("is-invalid");
    $(`input[name='${groupName}']:checked`).addClass("is-valid");
  });
}
