export function validateMarketFields(marketSelects) {
  let isValid = true;

  marketSelects.forEach((selectElement, index) => {
    const errorElementId = `market-select-error-${index}`;
    let errorElement = document.getElementById(errorElementId);

    if (!selectElement.value) {
      selectElement.classList.add("is-invalid");

      if (!errorElement) {
        errorElement = document.createElement("div");
        errorElement.id = errorElementId;
        errorElement.className = "invalid-feedback";
        errorElement.textContent = "Please select metro";
        errorElement.style.marginTop = "-30px";
        errorElement.style.marginLeft = "10px";
        selectElement.parentNode.appendChild(errorElement);
      }
      isValid = false;
    } else {
      selectElement.classList.remove("is-invalid");
      if (errorElement) {
        errorElement.remove();
      }
    }
  });

  return isValid;
}
