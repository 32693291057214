import { usMap, stateAbbr, stateNames } from "./us_map_svg";

document.addEventListener("turbo:render", function () {
  initializeMap();
});

document.addEventListener("turbo:load", function () {
  initializeMap();
  toggleMapVisibility();
});

window.addEventListener("pageshow", function (event) {
  if (event.persisted) {
    window.location.reload();
  }
});

document.addEventListener("turbo:load", () => {
  /* analytics */
  $("#toggle-map-btn").on("click", () => {
    if (typeof gtag === "function") {
      gtag("event", "tracked_click", {
        category: "Map Interaction",
        action: "Show/Hide Map",
        label: "Show Map Button",
        access_point: "States and Markets Section",
      });
    }
  });
});

function toggleMapVisibility() {
  const toggleButton = document.getElementById("toggle-map-btn");
  const mapContainer = document.getElementById("map-container");

  if (toggleButton && mapContainer) {
    toggleButton.addEventListener("click", function () {
      if (mapContainer.style.visibility === "hidden") {
        mapContainer.style.visibility = "visible";
        mapContainer.style.height = "auto";
        toggleButton.innerHTML = `
          <svg class="nav-icon me-2" height="16" width="16" fill="white">
            <use xlink:href="#svg-icon-metro" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
          </svg> Hide Map
        `;
      } else {
        mapContainer.style.visibility = "hidden";
        mapContainer.style.height = "0";
        toggleButton.innerHTML = `
          <svg class="nav-icon me-2" height="16" width="16" fill="white">
            <use xlink:href="#svg-icon-metro" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
          </svg> Show Map
        `;
      }
    });
  }
}

function initializeMap() {
  const container = document.getElementById("us-map-container");
  const svg = document.getElementById("us-map");

  if (!svg) {
    return;
  }
  while (svg.firstChild) {
    svg.removeChild(svg.firstChild);
  }

  const R = Raphael(svg, "100%", "100%");
  const attr = {
    fill: "#D7E9F6",
    stroke: "#0a2b43",
    "stroke-opacity": "0.4",
    "stroke-linejoin": "round",
    "stroke-miterlimit": "4",
    "stroke-width": "1.75",
    "stroke-dasharray": "none",
  };

  const smallStates = ["de", "ct", "ri", "nj", "dc", "nh", "vt", "ma", "md"];

  const smallStateYPositions = {};
  let yPosition = 220;

  function fetchRentalInfo(stateCode) {
    const stateAbbrCode = stateAbbr[stateCode.toLowerCase()];
    const stateFullName = stateNames[stateCode.toLowerCase()];
    const apiUrl = `/api/markets?state=${encodeURIComponent(stateAbbrCode)}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (!data || !Array.isArray(data)) {
          throw new Error("Invalid data format");
        }

        const marketsList = data
          .map((market) => {
            return `<li class="flex align-items-center justify-content-center"><a href="/metro/${market.slug}">${market.name}</a></li>`;
          })
          .join("");

        const marketsListContainer = document.createElement("ul");
        marketsListContainer.className =
          "markets__list list-inline mx-auto text-center";
        marketsListContainer.innerHTML = marketsList;

        if (data.length <= 1) {
          marketsListContainer.style.columns = 1;
        } else if (data.length == 2) {
          marketsListContainer.style.columns = 2;
        } else {
          marketsListContainer.style.columns = 3;
        }

        document.getElementById(
          "state-link"
        ).innerHTML = `<a href="/search?state=${stateAbbrCode}">Rental by state: ${stateFullName}</a>`;
        document.getElementById("metro-link").innerHTML = "";
        document.getElementById("metro-link").appendChild(marketsListContainer);

        const infoElement = document.getElementById("us-map-info");
        infoElement.classList.remove("d-none");
      })
      .catch((error) => {
        console.error("Error fetching market data:", error);
        document.getElementById(
          "metro-link"
        ).innerHTML = `<p>Error loading markets data</p>`;
      });
  }

  for (let state in usMap) {
    if (usMap.hasOwnProperty(state)) {
      (function (state) {
        const path = R.path(usMap[state]).attr(attr);
        const bbox = path.getBBox();
        const x = bbox.x + bbox.width / 2;
        const y = bbox.y + bbox.height / 2;
        const stateCode = stateAbbr[state];
        let rect;

        path.hover(
          function () {
            path.attr({ fill: "#64b7ea" });
            if (rect) rect.attr({ fill: "#fff" });
          },
          function () {
            path.attr({ fill: attr.fill });
            if (rect) rect.attr({ fill: "#fff" });
          }
        );

        path.click(function () {
          fetchRentalInfo(state);
        });

        if (smallStates.includes(state)) {
          if (!smallStateYPositions[state]) {
            smallStateYPositions[state] = yPosition;
            yPosition += 40;
          }
          const lineY = smallStateYPositions[state];
          rect = R.rect(900, lineY - 15, 60, 30, 4).attr({
            fill: "#D7E9F6",
            stroke: "#0a2b43",

            "stroke-width": 1,
          });
          const text = R.text(930, lineY, stateCode).attr({
            "font-size": 14,
            "font-weight": "bold",
            fill: "29587a",
          });

          const hoverIn = function () {
            path.attr({ fill: "#64b7ea" });
            rect.attr({ fill: "#64b7ea" });
          };
          const hoverOut = function () {
            path.attr({ fill: attr.fill });
            rect.attr({ fill: "#D7E9F6" });
          };

          rect.hover(hoverIn, hoverOut);
          text.hover(hoverIn, hoverOut);
          rect.click(function () {
            fetchRentalInfo(state);
          });
          text.click(function () {
            fetchRentalInfo(state);
          });

          path.hover(hoverIn, hoverOut);
        } else {
          let textX = x;
          let textY = y;
          if (state === "la") {
            textX += 15;
            textY += 15;
          }
          if (state === "mi") {
            textX += 15;
            textY += 15;
          }
          if (state === "fl") {
            textX += 50;
            textY += 15;
          }
          if (state === "ca") {
            textX += 0;
            textY += 15;
          }
          if (state === "id") {
            textX += 0;
            textY += 35;
          }
          const text = R.text(textX, textY, stateCode).attr({
            "font-size": 12,
            "font-weight": "bold",
            fill: "#29587a",
          });

          text.hover(
            function () {
              path.attr({ fill: "#64b7ea" });
            },
            function () {
              path.attr({ fill: attr.fill });
            }
          );
          text.click(function () {
            fetchRentalInfo(state);
          });
        }
      })(state);
    }
  }
}
