$(() => {
  /* analytics */
  const handleTrackedClick = (category, accessPoint) => {
    if (typeof gtag === "function") {
      gtag("event", "tracked_click", {
        category: category,
        access_point: accessPoint,
      });
    }
  };

  $("a#begin-rtp_doc_faq").on("click", () => {
    handleTrackedClick("Begin RTP", "FAQ");
  });

  $("a#begin-rtp_doc_process").on("click", () => {
    handleTrackedClick("Begin RTP", "Purchase Process");
  });

  $("a#begin-rtp_doc_tips").on("click", () => {
    handleTrackedClick("Begin RTP", "Tips");
  });

  $("a#begin-rtp_hpa_resources").on("click", function (event) {
    event.preventDefault();
    handleTrackedClick("Begin RTP", "Homepartners Resources");
    window.location = this.href;
  });

  $("a#begin-rtp_form_trigger").on("click", () => {
    handleTrackedClick("Begin RTP", "Form");
  });
  /* analytics */
});

document.addEventListener("turbo:load", () => {
  const form = document.getElementById("purchase_home_form");

  if (form) {
    form.addEventListener("submit", (event) => {
      const submitButton = form.querySelector("input[type='submit']");
      if (submitButton) {
        submitButton.disabled = true;
        submitButton.value = "Sending...";
        event.preventDefault();
        form.submit();
      }
    });
  }
});
