document.addEventListener("turbo:load", initializeVideoModals);

function initializeVideoModals() {
  let VimeoVideo = {
    opened: false,
    videoId: "",
  };

  const testimonialVideo = document.querySelector("#testimonial-video");
  const watchVideo = document.querySelector("#watch-video");
  const tourVideo = document.querySelector("#tour-video");

  if (testimonialVideo) {
    testimonialVideo.addEventListener("shown.bs.modal", function (event) {
      const link = event.relatedTarget;
      if (link) {
        VimeoVideo.videoId = link.getAttribute("data-video_id");
        const dialog = testimonialVideo.querySelector(".modal-dialog");
        if (dialog) {
          const width = dialog.clientWidth;
          const height = width * 0.5625;
          testimonialVideo.querySelector(
            ".modal-body .video"
          ).innerHTML = `<iframe src="https://player.vimeo.com/video/${VimeoVideo.videoId}"
                    height="${height}"
                    frameborder="0"
                    webkitallowfullscreen mozallowfullscreen allowfullscreen>
            </iframe>`;
          VimeoVideo.opened = true;
        }
      }
    });

    testimonialVideo.addEventListener("hidden.bs.modal", function () {
      const videoContainer =
        testimonialVideo.querySelector(".modal-body .video");
      if (videoContainer) {
        videoContainer.innerHTML = "";
        VimeoVideo.opened = false;
      }
    });
  }

  if (watchVideo) {
    watchVideo.addEventListener("shown.bs.modal", function (event) {
      const link = event.relatedTarget;
      if (link) {
        VimeoVideo.videoId = link.getAttribute("data-video_id");
        const dialog = watchVideo.querySelector(".modal-dialog");
        if (dialog) {
          const width = dialog.clientWidth;
          const height = width * 0.5625;
          watchVideo.querySelector(
            ".modal-body .video"
          ).innerHTML = `<iframe src="https://player.vimeo.com/video/${VimeoVideo.videoId}"
                    height="${height}"
                    frameborder="0"
                    webkitallowfullscreen mozallowfullscreen allowfullscreen>
            </iframe>`;
          VimeoVideo.opened = true;
        }
      }
    });

    watchVideo.addEventListener("hidden.bs.modal", function () {
      const videoContainer = watchVideo.querySelector(".modal-body .video");
      if (videoContainer) {
        videoContainer.innerHTML = "";
        VimeoVideo.opened = false;
      }
    });
  }

  if (tourVideo) {
    tourVideo.addEventListener("shown.bs.modal", function (event) {
      const link = event.relatedTarget;
      if (link) {
        const videoId = link.getAttribute("data-video_id");
        const dialog = tourVideo.querySelector(".modal-dialog");
        if (dialog) {
          const width = dialog.clientWidth;
          const height = width * 0.5625;
          tourVideo.querySelector(
            ".modal-body .video"
          ).innerHTML = `<iframe height="${height}"
                    src="https://www.youtube.com/embed/${videoId}?autoplay=1&playsinline=1&showinfo=0&rel=0&iv_load_policy=3"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>`;
        }
      }
    });

    tourVideo.addEventListener("hidden.bs.modal", function () {
      const videoContainer = tourVideo.querySelector(".modal-body .video");
      if (videoContainer) {
        videoContainer.innerHTML = "";
      }
    });
  }
}
