document.addEventListener("turbo:load", () => {
  const appBanner = document.querySelector(".app-banner");
  if (appBanner) {
    const isVisible = sessionStorage.getItem("banner_removed") !== "1";
    appBanner.classList.toggle("d-none", !isVisible);
    appBanner.classList.toggle("d-block", isVisible);

    document
      .querySelector(".app-banner-close-btn")
      ?.addEventListener("click", () => {
        appBanner.classList.add("d-none");
        appBanner.classList.remove("d-block");
        sessionStorage.setItem("banner_removed", "1");
      });
  }
});
