export function addCheckboxEventListeners(countyContainer) {
  const updateColorForCheckboxes = (checkboxes, color) => {
    checkboxes.each(function () {
      $(this).closest(".form-check").css("color", color);
    });
  };

  const checkIfAnyCheckboxInColumnChecked = (selector) => {
    return countyContainer.find(selector).is(":checked");
  };

  const evaluateColorsAndErrors = () => {
    const anyVacantChecked =
      checkIfAnyCheckboxInColumnChecked(".vacant-checkbox");
    const anyOccupiedChecked =
      checkIfAnyCheckboxInColumnChecked(".occupied-checkbox");
    const serviceErrorContainer = countyContainer.find(
      ".services-error-container"
    );

    if (!anyVacantChecked && !anyOccupiedChecked) {
      updateColorForCheckboxes(
        countyContainer.find(".vacant-checkbox, .occupied-checkbox"),
        "rgb(220, 53, 69)"
      );
      serviceErrorContainer.text(
        "Please select at least one service and indicate if these services are offered for vacant properties, occupied properties or both."
      );
      serviceErrorContainer.css("color", "rgb(220, 53, 69)");
    } else {
      updateColorForCheckboxes(
        countyContainer.find(".vacant-checkbox, .occupied-checkbox"),
        ""
      );
      serviceErrorContainer.text("");
      serviceErrorContainer.css("color", "");
    }
  };

  countyContainer.find(".all-vacant-checkbox").on("change", function () {
    countyContainer.find(".vacant-checkbox").prop("checked", this.checked);
    evaluateColorsAndErrors();
  });

  countyContainer.find(".all-occupied-checkbox").on("change", function () {
    countyContainer.find(".occupied-checkbox").prop("checked", this.checked);
    evaluateColorsAndErrors();
  });
}
