import { validateStateFields } from "./validateStateFields";
import { validateMarketFields } from "./validateMarketFields";
import { validateAllCheckboxContainers } from "./validateCheckboxes";
export function initializeCustomStateValidation() {
  const form = document.getElementById("vendor_contact_form");
  if (form) {
    form.addEventListener("submit", function (event) {
      const stateSelects = document.querySelectorAll(".state-select");
      const marketSelects = document.querySelectorAll(".market-select");
      const isStateValid = validateStateFields(stateSelects);
      const isMarketValid = validateMarketFields(marketSelects);
      const isCheckboxValid = validateAllCheckboxContainers();

      if (!isStateValid || !isMarketValid || !isCheckboxValid) {
        event.preventDefault();
      }
    });
  }
}
