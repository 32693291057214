export function updateSelectStateOptions() {
  const selectedStates = new Set(
    $(".vendor-state-picker")
      .map(function () {
        return $(this).val();
      })
      .get()
  );

  $(".vendor-state-picker").each(function () {
    const currentPicker = $(this);
    const currentValue = currentPicker.val();
    currentPicker.find("option").each(function () {
      const optionValue = $(this).val();
      $(this).prop(
        "disabled",
        selectedStates.has(optionValue) && optionValue !== currentValue
      );
    });
  });
}

export function updateSelectMarketOptions() {
  const selectedMarkets = new Set(
    $(".vendor-metro-picker")
      .map(function () {
        return $(this).val();
      })
      .get()
  );

  $(".vendor-metro-picker").each(function () {
    const currentPicker = $(this);
    const currentValue = currentPicker.val();

    currentPicker.find("option").each(function () {
      const optionValue = $(this).val();
      $(this).prop(
        "disabled",
        selectedMarkets.has(optionValue) && optionValue !== currentValue
      );
    });
  });
}
