import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vendor-inquiry-radio-check"
export default class extends Controller {
  static targets = [ "invoiceInquiryForm", "over30DaysRadioBtn", "notOver30DaysRadioBtn", "collapsibleWarning" ]

  connect() {
  }

  toggleOver30DaysWarning() {
    if (this.notOver30DaysRadioBtnTarget.checked) {
      this.invoiceInquiryFormTarget.disabled = true
      this.collapsibleWarningTarget.classList.remove("collapse")
    }

    if (this.over30DaysRadioBtnTarget.checked) {
      this.invoiceInquiryFormTarget.disabled = false
      this.collapsibleWarningTarget.classList.add("collapse")
    }

    this.notOver30DaysRadioBtnTarget.disabled = true
    this.over30DaysRadioBtnTarget.disabled =  true
  }
}
