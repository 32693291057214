document.addEventListener("turbo:load", function () {
  const contactForm = document.querySelector(".contact-form");
  const sendButton = contactForm ? contactForm.querySelector(".send") : null;
  const thankYouMessage = document.querySelector(".thank-you");

  if (sendButton) {
    sendButton.addEventListener("click", function () {
      if (thankYouMessage) {
        thankYouMessage.style.display = "block";
      }
      if (contactForm) {
        contactForm.style.display = "none";
      }
    });
  }
});
