function disableCityPicker(isMobile = false) {
  const selector = isMobile ? "#city-picker-mobile" : "#city-picker";
  $(selector).empty();
  $(selector).prop("disabled", true);
}

function populateCityPicker(cities, isMobile = false) {
  const selector = isMobile ? "#city-picker-mobile" : "#city-picker";
  $(selector).removeAttr("disabled");
  $(selector).empty();
  $(selector).append('<option value="">All Cities</option>');
  cities.forEach((city) => {
    $(selector).append(`<option value="${city}">${city}</option>`);
  });
}

document.addEventListener("turbo:load", initializeSearch);

function initializeSearch() {
  $(() => {
    $("#state-picker").on("change", (event) => {
      $.get(`/cities?state=${event.target.value}`)
        .done((data) => {
          if (data) {
            populateCityPicker(data);
          } else {
            disableCityPicker();
          }
        })
        .fail(() => {
          disableCityPicker();
        });
    });

    $("#state-picker-mobile").on("change", (event) => {
      $.get(`/cities?state=${event.target.value}`)
        .done((data) => {
          if (data) {
            populateCityPicker(data, true);
          } else {
            disableCityPicker(true);
          }
        })
        .fail(() => {
          disableCityPicker(true);
        });
    });

    $(".redirect-warning-close-btn").on("click", (event) => {
      const currentElem = event.currentTarget;
      const propertyIndex = currentElem.dataset.propertyIndex;
      window.location = $(`#tour-yourself-search-${propertyIndex}`).attr(
        "href"
      );
    });

    /* analytics */
    $("#tour_yourself").on("change", function () {
      if (typeof gtag === "function" && $(this).is(":checked")) {
        gtag("event", "check", {
          category: "Tour Yourself Search",
          access_point: "Tour Yourself Filter",
        });
      }
      $("form#search-form").submit();
    });

    $("#tour_yourself_mobile").on("change", function () {
      if (typeof gtag === "function" && $(this).is(":checked")) {
        gtag("event", "check", {
          category: "Tour Yourself Search",
          access_point: "Tour Yourself Filter",
        });
      }
      $("form#search-form-mobile").submit();
    });

    $(".tour-yourself-search").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Tour Yourself",
          access_point: "Search Page",
        });
      }
    });

    $(".c-search__apply").on("click", (event) => {
      event.preventDefault();
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Apply",
          access_point: "Search Page",
        });
      }
      window.location = event.currentTarget.href;
    });

    $("select#sort_by").on("change", (event) => {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("sort", event.target.value);
      window.location.search = urlParams;
    });

    $("#header-menu-search").on("click", (event) => {
      event.preventDefault();
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Search",
          access_point: "Main Navigation",
        });
      }
      window.location = event.currentTarget.href;
    });

    $("#home-address-search-form").on("submit", (event) => {
      event.preventDefault();
      if (typeof gtag === "function") {
        gtag("event", "submit", {
          category: "Search",
          access_point: "Landing by Address",
        });
      }
      event.currentTarget.submit();
    });

    $("#home-market-search-form").on("submit", (event) => {
      event.preventDefault();
      if (typeof gtag === "function") {
        gtag("event", "submit", {
          category: "Search",
          access_point: "Landing by Market",
        });
      }
      window.location = event.currentTarget.querySelector("select").value;
    });
  });
}
