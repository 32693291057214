import "@hotwired/turbo-rails";
import "./controllers";
import {
  initializeValidation,
  initializeJQueryValidation,
  initializeReferralToggle,
  addCheckboxEventListeners,
  updateSelectMarketOptions,
  initializeCustomStateValidation,
} from "./utils";

document.addEventListener("turbo:load", () => {
  initializeJQueryValidation();
  initializeCustomStateValidation();
});

document.addEventListener("turbo:load", () => {
  $(document).on("change", ".market-select", async function () {
    const metroName = $(this).find("option:selected").text();
    const countyContainer = $(this)
      .closest(".market-item")
      .find(".county-container");

    await loadCountyData(metroName, countyContainer);
  });

  async function loadCountyData(metroName, countyContainer) {
    const apiUrl = `/api/counties?market=${encodeURIComponent(metroName)}`;
    const serviceTemplate = $("template.serviceTemplate")[0];
    const uniqueId = `countyContainer_${metroName.replace(/\s+/g, "_")}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok)
        throw new Error(`Failed to fetch: ${response.statusText}`);
      const data = await response.json();

      countyContainer.empty();
      countyContainer.attr("id", uniqueId);

      if (data && data.length > 0) {
        countyContainer.append(
          '<h5 class="form-label d-block mb-2">Counties</h5>'
        );
        countyContainer.append("<div class='w-100'></div>");

        data.forEach((county) => {
          const countyId = county.replace(/\s+/g, "_");
          const checkboxHTML = `
            <div class="form-check-county form-check-inline mr-2">
              <input type="checkbox" class="form-check-input county-checkbox p-0 me-2" id="county_${countyId}" name="vendor_contact[market_data[][markets][][counties][][name]]" value="${county}">
              <label class="form-check-label" for="county_${countyId}">${county}</label>
            </div>`;
          countyContainer.append(checkboxHTML);
        });

        if (serviceTemplate) {
          const serviceContent = serviceTemplate.content.cloneNode(true);
          countyContainer.append(serviceContent);
          initializeValidation(`#${uniqueId}`);
          addCheckboxEventListeners(countyContainer);
        }
      } else {
        countyContainer.append(
          '<div class="alert alert-warning">No Counties Available - Please select different metro</div>'
        );
      }
    } catch (error) {
      console.error("Failed to load counties data from:", apiUrl, error);
    }
  }
});

document.addEventListener("turbo:load", () => {
  const selectedMetros = {};

  function updateSelectedMetros() {
    $(".vendor-metro-picker").each(function () {
      const stateId = $(this)
        .closest(".state-item")
        .find(".vendor-state-picker")
        .val();
      selectedMetros[stateId] = $(this).val();
    });
  }

  function filterMetroOptions() {
    $(".vendor-metro-picker").each(function () {
      const stateId = $(this)
        .closest(".state-item")
        .find(".vendor-state-picker")
        .val();
      const currentPicker = $(this);
      const selectedOption = selectedMetros[stateId];

      currentPicker.find("option").each(function () {
        const optionValue = $(this).attr("value");
        $(this).prop("disabled", selectedOption === optionValue);
      });
    });
  }

  async function loadMetroData(state, metroPicker, callback) {
    const apiUrl = `/api/markets?state=${encodeURIComponent(state)}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok)
        throw new Error(`Failed to fetch: ${response.statusText}`);
      const data = await response.json();

      metroPicker.empty();
      metroPicker.append(
        $("<option></option>").attr("value", "").text("Please Select Metro")
      );

      if (data && data.length > 0) {
        data.forEach(function (market) {
          var option = $("<option></option>")
            .attr("value", market.name)
            .text(market.name);
          metroPicker.append(option);
        });

        if (selectedMetros[state]) {
          metroPicker.val(selectedMetros[state]);
        }
        metroPicker.prop("disabled", false);
      } else {
        metroPicker
          .prop("disabled", true)
          .append($("<option></option>").text("No Metro Available"));
      }

      if (callback) callback();
    } catch (error) {
      console.error("Failed to load metro data from:", apiUrl, error);
      metroPicker
        .prop("disabled", true)
        .append($("<option></option>").text("Error loading data"));
    } finally {
      updateSelectMarketOptions();
    }
  }

  $(document).on("change", ".vendor-state-picker", function () {
    const statePicker = $(this);
    const state = statePicker.val();
    const stateItem = statePicker.closest(".state-item");
    const metroPicker = stateItem.find(".vendor-metro-picker");

    loadMetroData(state, metroPicker, () => {
      updateSelectMarketOptions();
    });
    updateSelectMarketOptions(); // TODO

    stateItem
      .find(".county-container")
      .empty()
      .append(
        "<div class='alert alert-warning'>No Counties Available - Please select metro</div>"
      );
  });

  $(document).on("change", ".vendor-metro-picker", () => {
    updateSelectMarketOptions();
  });

  $(document).on("click", ".btn-add-metro", function () {
    const row = $(this).closest(".state-item");
    const statePicker = row.find(".vendor-state-picker");
    const state = statePicker.val();
    const metroPicker = row.find(".vendor-metro-picker").last();

    loadMetroData(state, metroPicker, () => {
      updateSelectedMetros();
      filterMetroOptions();
    });
  });
});

document.addEventListener("turbo:load", () => {
  initializeReferralToggle();
});
