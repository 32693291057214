import { Controller } from "@hotwired/stimulus";
import {
  updateSelectStateOptions,
  updateSelectMarketOptions,
  validateStateFields,
  validateMarketFields,
} from "../../javascript/utils";
export default class extends Controller {
  static targets = [
    "stateSelect",
    "addMarketButton",
    "stateItem",
    "marketItem",
    "stateTemplate",
    "marketsContainer",
    "marketTemplate",
    "addStateButton",
  ];

  connect() {
    this.renderInitialState();
    this.checkAddStateButton();
    this.updateRemoveButtons();
  }

  renderInitialState() {
    if (this.hasStateTemplateTarget) {
      this.addState();
    } else {
      console.error("State template is missing!");
    }
  }

  addState() {
    if (!this.validateStates()) {
      return;
    }

    const content = this.stateTemplateTarget.content.cloneNode(true);
    const newStateItem = content.querySelector(".state-item");
    const uniqueId = `state-select-${Date.now()}`;
    newStateItem.querySelector(".state-select").id = uniqueId;

    this.element.appendChild(content);
    this.updateRemoveButtons();
    this.checkAddStateButton();
    updateSelectStateOptions();

    const marketsContainer = newStateItem.querySelector(
      '[data-vendor-form-target="marketsContainer"]'
    );
    this.addMarketToContainer(marketsContainer);
  }

  removeState(event) {
    event.preventDefault();
    const stateItem = event.currentTarget.closest(".state-item");
    if (stateItem && this.stateItemTargets.length > 1) {
      stateItem.remove();
      updateSelectStateOptions();
      this.updateRemoveButtons();
    }
    this.checkAddStateButton();
  }

  updateRemoveButtons() {
    this.stateItemTargets.forEach((item, index) => {
      const removeButton = item.querySelector(
        '[data-vendor-form-target="removeStateButton"]'
      );
      if (index === 0) {
        removeButton.style.display = "none";
      } else {
        removeButton.style.display = "block";
      }
    });
  }

  checkStateSelected(event) {
    const selectElement = event.target;
    const stateItem = selectElement.closest(".state-item");
    const addMarketButton = stateItem.querySelector(
      '[data-vendor-form-target="addMarketButton"]'
    );

    if (selectElement.value) {
      addMarketButton.classList.remove("disabled");
      selectElement.classList.remove("is-invalid");
      const errorElement = stateItem.querySelector(".invalid-feedback");
      if (errorElement) {
        errorElement.remove();
      }
    } else {
      addMarketButton.classList.add("disabled");
    }
  }

  checkAddStateButton() {
    const addStateButton = this.element.querySelector(
      '[data-vendor-form-target="addStateButton"]'
    );
    addStateButton.classList.remove("disabled");
  }

  handleStateChange(selectElement, marketsContainer) {
    if (selectElement.value !== "") {
      this.addMarketToContainer(marketsContainer);
    }
  }

  addMarket(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const marketsContainer = button
      .closest(".state-item")
      .querySelector('[data-vendor-form-target="marketsContainer"]');
    this.addMarketToContainer(marketsContainer);
  }

  addMarketToContainer(marketsContainer) {
    if (!this.hasMarketTemplateTarget) {
      console.error("Market template is missing!");
      return;
    }

    const marketContent = this.marketTemplateTarget.content.cloneNode(true);
    const newMarketItem = marketContent.querySelector(".market-item");
    const uniqueId = `market-select-${Date.now()}`;
    newMarketItem.querySelector(".market-select").id = uniqueId;

    marketsContainer.appendChild(marketContent);
    updateSelectMarketOptions();

    const marketSelects = marketsContainer.querySelectorAll(".market-select");
    const lastMarketSelect = marketSelects[marketSelects.length - 1];

    if (lastMarketSelect) {
      lastMarketSelect.addEventListener("change", (e) => {
        if (e.target.value) {
          lastMarketSelect.classList.remove("is-invalid");
          lastMarketSelect.classList.add("is-valid");
        } else {
          lastMarketSelect.classList.remove("is-valid");
          lastMarketSelect.classList.add("is-invalid");
        }
      });
    }
  }

  removeMarket(event) {
    event.preventDefault();
    const marketItem = event.currentTarget.closest(".market-item");
    if (marketItem && marketItem.parentNode.children.length > 1) {
      marketItem.remove();
      updateSelectMarketOptions();
    }
  }

  validateStates() {
    return validateStateFields(this.stateSelectTargets);
  }

  validateMarkets() {
    const marketSelects = this.element.querySelectorAll(".market-select");
    return validateMarketFields(marketSelects);
  }
}
