document.addEventListener("turbo:load", () => {
  const path = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  if (
    (path === "/search" || path.startsWith("/metro/")) &&
    !searchParams.has("page")
  ) {
    searchParams.set("page", "all");
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  }

  const mapContainer = document.getElementById("map-search");
  if (mapContainer) {
    initMap();
  }
});

async function fetchProperties() {
  try {
    const mapContainer = document.getElementById("map-container-grid");
    if (!mapContainer) {
      return [];
    }
    const spinner = createSpinner();
    mapContainer.appendChild(spinner);
    const searchParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;

    let apiUrl;

    if (searchParams.get("page") === "all") {
      searchParams.set("per_page", "5000");
    } else {
      searchParams.set("per_page", "12");
    }

    if (searchParams.has("market")) {
      const marketSlug = searchParams.get("market");
      apiUrl = `/api/properties/search?market=${encodeURIComponent(
        marketSlug
      )}&${searchParams.toString()}`;
    } else if (path.startsWith("/metro/")) {
      const marketSlug = path.split("/metro/")[1];
      searchParams.set("market", marketSlug);
      apiUrl = `/api/properties/search?${searchParams.toString()}`;
    } else {
      apiUrl = `/api/properties/search?${searchParams.toString()}`;
    }

    const response = await fetch(apiUrl);
    const data = await response.json();

    mapContainer.removeChild(spinner);

    return data;
  } catch (error) {
    console.error("Error fetching property data:", error);
    const mapContainer = document.getElementById("map-container-grid");
    const spinner = document.getElementById("map-loader");

    if (mapContainer && spinner) {
      mapContainer.removeChild(spinner);
    }

    return [];
  }
}

function createSpinner() {
  const spinner = document.createElement("div");
  spinner.id = "map-loader";
  spinner.style.position = "absolute";
  spinner.style.top = "0";
  spinner.style.left = "0";
  spinner.style.width = "100%";
  spinner.style.height = "100%";
  spinner.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
  spinner.style.display = "flex";
  spinner.style.justifyContent = "center";
  spinner.style.alignItems = "center";
  spinner.style.zIndex = "1000";

  const spinnerElement = document.createElement("div");
  spinnerElement.className = "spinner-border text-primary";
  spinnerElement.role = "status";
  spinnerElement.style.width = "3rem";
  spinnerElement.style.height = "3rem";
  spinnerElement.style.borderWidth = "0.4rem";
  spinnerElement.style.borderRadius = "50%";
  spinnerElement.style.borderTopColor = "#2F92D4";
  spinnerElement.style.borderRightColor = "#2F92D4";
  spinnerElement.style.borderBottomColor = "#2F92D4";
  spinnerElement.style.borderLeftColor = "transparent";

  const spinnerText = document.createElement("span");
  spinnerText.className = "visually-hidden";
  spinnerText.textContent = "Loading...";
  spinnerElement.appendChild(spinnerText);
  spinner.appendChild(spinnerElement);

  return spinner;
}

async function initMap() {
  const properties = await fetchProperties();
  updateMap(properties);
}

function updateMap(properties) {
  const mapPin = "/house-marker.svg";
  const centerMap = { lat: 39.0, lng: -100.0 };
  const mapOptions = {
    center: centerMap,
    zoom: 3,
    styles: [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#e9e9e9",
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 29,
          },
          {
            weight: 0.2,
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 18,
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#dedede",
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ffffff",
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            saturation: 36,
          },
          {
            color: "#333333",
          },
          {
            lightness: 40,
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
          {
            color: "#f2f2f2",
          },
          {
            lightness: 19,
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#fefefe",
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#fefefe",
          },
          {
            lightness: 17,
          },
          {
            weight: 1.2,
          },
        ],
      },
    ],
  };
  const map = new google.maps.Map(
    document.getElementById("map-search"),
    mapOptions
  );

  const bounds = new google.maps.LatLngBounds();
  let openInfoWindow = null;

  const markers = properties
    .map((property) => {
      const lat = parseFloat(property.latitude);
      const lng = parseFloat(property.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        const marker = new google.maps.Marker({
          position: { lat: lat, lng: lng },
          icon: mapPin,
        });

        let rentDisplay;
        if (
          property.rentSchedules &&
          Object.keys(property.rentSchedules).length > 0
        ) {
          const rents = Object.values(property.rentSchedules).map(
            (schedule) => schedule.monthlyRent || schedule
          );
          const minRent = Math.min(...rents);
          const maxRent = Math.max(...rents);
          rentDisplay = `$${minRent} - $${maxRent}`;
        } else {
          rentDisplay = `$${property.rent}`;
        }

        const availableDate = property.available_date
          ? `${property.available_date.month}/${property.available_date.year}`
          : "N/A";
        const formattedArea =
          property.area > 0 ? property.area.toLocaleString("en-US") : "";

        const infoWindowContent = `
          <div class="custom-map-infobox" style="font-family: Arial, sans-serif; max-width: 100%; height: auto; position: relative; padding: 4px;">
            <!-- Desktop Version -->
            <div class="d-none d-lg-block m-2">
              <div style="position: relative; margin-bottom: 10px;">
                <img class="img-fluid rounded-1" src="${
                  property.images[0] || ""
                }" style="width: 100%; max-width: 320px; max-height: 160px; display: block; margin: 0 auto; object-fit: cover;">
              </div>
              <div style="font-size: 16px; font-weight: bold; color: #007bff; text-align: center; padding: 0 5px; margin-bottom: 10px;">
                <a href="/homes/${
                  property.id
                }" style="text-decoration: none; color: #007bff;" tabindex="0">
                  ${property.heading}
                </a>
              </div>
              <div class="text-center" style="font-size: 14px;"><strong>Rent:</strong> <span style="font-weight: bold;">${rentDisplay}</span></div>
              <div class="text-center" style="font-size: 14px;"><strong>Available:</strong> <span style="font-weight: bold;">${availableDate}</span></div>
              <div class="row d-flex align-content-center justify-content-center mb-3">
                <div class="col-auto py-1 px-2">
                  <span class="text-extra-bold text-dark-grey">${
                    property.bedrooms
                  }</span><span class="text-muted"> beds</span>
                  <span class="separator text-small mx-1" aria-hidden="true"> ● </span>
                  <span class="text-extra-bold text-dark-grey">${
                    property.bathrooms
                  }</span><span class="text-muted"> baths</span>
                  <span class="separator mx-1" aria-hidden="true"> ● </span>
                  <span class="text-extra-bold text-dark-grey">${formattedArea}</span><span class="text-muted"> sqft</span>

                </div>
              </div>
            </div>
            <!-- Mobile Version -->
            <div class="d-block d-lg-none m-2">
              <div class="text-center mb-2" style="font-size: 13px; font-weight: bold;">
                <a href="/homes/${
                  property.id
                }" style="text-decoration: none; color: #007bff;" tabindex="0">
                  ${property.heading}
                </a>
              </div>
              <div class="row g-0">
                <div class="col-5 mb-1">
                  <img class="rounded-1" src="${
                    property.images[0] || ""
                  }" style="width: 100%; max-width: 100px; height: auto; max-height: 80px; object-fit: cover;">
                </div>
                <div class="col-7 ps-2" style="font-size: 10px;">
                  <div><strong>Rent:</strong> <span style="font-weight: bold;">${rentDisplay}</span></div>
                  <div><strong>Available:</strong> <span style="font-weight: bold;">${availableDate}</span></div>
                  <div><span class="text-extra-bold text-dark-grey">${
                    property.bedrooms
                  }</span><span class="text-muted"> beds</span></div>
                  <div><span class="text-extra-bold text-dark-grey">${
                    property.bathrooms
                  }</span><span class="text-muted"> baths</span></div>
                  <div class="pb-2"><span class="text-extra-bold text-dark-grey">${formattedArea}</span><span class="text-muted"> sqft</span></div>

                </div>
              </div>
            </div>
          </div>
        `;

        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
          maxWidth: 260,
        });

        google.maps.event.addListener(marker, "click", () => {
          if (openInfoWindow) {
            openInfoWindow.close();
          }
          infoWindow.open(map, marker);
          openInfoWindow = infoWindow;
        });

        google.maps.event.addListener(infoWindow, "closeclick", () => {
          openInfoWindow = null;
        });

        bounds.extend(new google.maps.LatLng(lat, lng));
        return marker;
      }
    })
    .filter((marker) => marker !== undefined);

  if (markers.length > 0) {
    map.fitBounds(bounds);
  } else {
    map.setCenter(centerMap);
    map.setZoom(3);
  }

  const clusterStyles = [
    {
      url: "/map-cluster2.svg",
      height: 40,
      width: 40,
      textColor: "black",
      textSize: 14,
    },
  ];

  const clusterOptions = {
    styles: clusterStyles,
    maxZoom: 15,
  };

  new MarkerClusterer(map, markers, clusterOptions);
}

document.addEventListener("turbo:load", function () {
  const toggleMapButton = document.getElementById("toggle-map-button");
  const mapContainer = document.getElementById("map-container-grid");
  const cardsContainer = document.getElementById("cards-container");

  if (toggleMapButton && mapContainer && cardsContainer) {
    const isMapHidden = localStorage.getItem("isMapHidden") === "true";

    if (isMapHidden) {
      mapContainer.classList.add("hidden");
      cardsContainer.classList.add("full-width");
      toggleMapButton.innerHTML = `
        <svg class="nav-icon me-2" height="22" width="22" fill="white">
          <use xlink:href="#svg-icon-metro" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
        </svg> Show Map
      `;
    } else {
      mapContainer.classList.remove("hidden");
      cardsContainer.classList.remove("full-width");
    }

    toggleMapButton.addEventListener("click", function () {
      const mapIsCurrentlyHidden = mapContainer.classList.contains("hidden");
      mapContainer.classList.add("transitioning");

      if (mapIsCurrentlyHidden) {
        mapContainer.classList.remove("hidden");
        cardsContainer.classList.remove("full-width");
        toggleMapButton.innerHTML = `
          <svg class="nav-icon me-2" height="22" width="22" fill="white">
            <use xlink:href="#svg-icon-metro" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
          </svg> Hide Map
        `;
        localStorage.setItem("isMapHidden", "false");
      } else {
        mapContainer.classList.add("hidden");
        cardsContainer.classList.add("full-width");
        toggleMapButton.innerHTML = `
          <svg class="nav-icon me-2" height="22" width="22" fill="white">
            <use xlink:href="#svg-icon-metro" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
          </svg> Show Map
        `;
        localStorage.setItem("isMapHidden", "true");
      }

      mapContainer.addEventListener(
        "transitionend",
        function () {
          mapContainer.classList.remove("transitioning");
        },
        { once: true }
      );
    });

    async function fetchPropertiesAndUpdate() {
      const properties = await fetchProperties();
      if (!isMapHidden) {
        updateMap(properties);
      }
    }

    fetchPropertiesAndUpdate();
  }
});
