export function initializeReferralToggle() {
  const referralTrue = document.getElementById("referred_true");
  const referralFalse = document.getElementById("referred_false");
  const referralName = document.getElementById("referral_name");

  function toggleReferralName() {
    if (referralTrue.checked) {
      referralName.disabled = false;
    } else {
      referralName.disabled = true;
      referralName.value = "";
    }
  }

  if (referralTrue && referralFalse && referralName) {
    referralTrue.addEventListener("change", toggleReferralName);
    referralFalse.addEventListener("change", toggleReferralName);

    toggleReferralName();
  }
}
