function setCookie(name, value, minutes) {
  const d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length);
  }
  return null;
}

document.addEventListener("turbo:load", initializePropertyPage);

function initializePropertyPage() {
  const contactAgentButton = document.getElementById("contact-agent");
  const formContainer = document.getElementById("contact-form-container");
  const form = document.getElementById("contact-form");
  const successMessage = document.getElementById("contact-success");
  const alreadySubmittedMessage = document.getElementById(
    "already-submitted-message"
  );
  let propertyId;

  const contactSection = document.querySelector(".contact-section");
  if (contactSection) {
    propertyId = contactSection.dataset.id;
  }

  if (contactAgentButton) {
    contactAgentButton.addEventListener("click", function () {
      formContainer.classList.toggle("d-none");
    });
  }

  const originalHeader = document.getElementById("sticky-header");
  const fixedFooterBar = document.getElementById("fixed-footer-bar");

  if (originalHeader && fixedFooterBar) {
    const observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].intersectionRatio === 0) {
          fixedFooterBar.classList.remove("hidden");
        } else {
          fixedFooterBar.classList.add("hidden");
        }
      },
      { threshold: [0] }
    );

    observer.observe(originalHeader);
  }

  if (propertyId && form) {
    if (getCookie(`formSubmitted_${propertyId}`)) {
      alreadySubmittedMessage.classList.remove("d-none");
      form.classList.add("d-none");
    } else {
      form.addEventListener("turbo:submit-end", (event) => {
        const detail = event.detail;

        if (detail.success) {
          setCookie(`formSubmitted_${propertyId}`, "true", 10);
          if (typeof gtag === "function") {
            gtag("event", "submit", {
              category: "Contact Agent",
              access_point: "Property Page Tab",
            });
          }
          successMessage.classList.remove("d-none");
          form.classList.add("d-none");
        } else {
          alert("Your contact submission failed. Please try again later.");
        }
      });
    }
  }

  $("#scrollToRentTable").on("click", () => {
    const rentTableSection = $(".rent-info-list");
    if (rentTableSection.length) {
      $("html, body").animate(
        {
          scrollTop: rentTableSection.offset().top,
        },
        800
      );
    }
  });

  $("#3d-tour-btn").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "click", {
        event_category: "Property Page",
        event_action: "3D Tour",
      });
    }
  });

  $("#tour-yourself-behome247").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "click", {
        event_category: "Property Page",
        event_action: "Tour Yourself BeHome247",
      });
    }
  });

  $("#tour-yourself-rently").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "click", {
        event_category: "Property Page",
        event_action: "Tour Yourself Rently",
      });
    }
  });

  $("#contact-agent-rently").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "tracked_click", {
        event_category: "Contact Agent Rently",
        event_label: "Property Page",
      });
    }
  });

  $("#contact-agent").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "tracked_click", {
        event_category: "Contact Agent Form",
        event_label: "Property Page",
      });
    }
  });

  $("#map-tab").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "tab_click", {
        event_category: "Map Tab",
        event_label: "Property Page",
      });
    }
  });

  $("#streetview-tab").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "tab_click", {
        event_category: "Street View Tab",
        event_label: "Property Page",
      });
    }
  });

  $("#twitter-share-button").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "social_share", {
        event_category: "Twitter Share",
        event_label: "Property Page",
      });
    }
  });

  $("#facebook-share-button").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "social_share", {
        event_category: "Facebook Share",
        event_label: "Property Page",
      });
    }
  });

  $("#pinterest-share-button").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "social_share", {
        event_category: "Pinterest Share",
        event_label: "Property Page",
      });
    }
  });

  $("#email-share-button").on("click", function () {
    if (typeof gtag === "function") {
      gtag("event", "social_share", {
        event_category: "Email Share",
        event_label: "Property Page",
      });
    }
  });

  $(".c-property__apply").on("click", function (event) {
    event.preventDefault();
    if (typeof gtag === "function") {
      gtag("event", "tracked_click", {
        event_category: "Apply",
        event_label: "Property Page",
      });
    }
    window.location = event.currentTarget.href;
  });
}

// forces a datepicker input format when the form is submitted
$("#search-form").on("submit", function (event) {
  let dateInput = event.target.querySelector("#available_date").value;
  $("#available_date").datepicker("update", dateInput);
});
$("#search-form-mobile").on("submit", function (event) {
  let dateInput = event.target.querySelector("#available_date").value;
  $("#available_date").datepicker("update", dateInput);
});
document.addEventListener("turbo:load", function () {
  const forms = document.querySelectorAll(".needs-validation");

  Array.prototype.slice.call(forms).forEach(function (form) {
    const commentsField = form.querySelector('textarea[name="comments"]');
    const emailField = form.querySelector('input[name="email"]');

    form.addEventListener(
      "submit",
      function (event) {
        if (
          commentsField &&
          commentsField.value.trim() !== "" &&
          !/^[a-zA-Z0-9\s.]*$/.test(commentsField.value)
        ) {
          commentsField.setCustomValidity(
            "Comments can contain only letters and numbers."
          );
        } else {
          commentsField.setCustomValidity("");
        }

        const emailPattern = /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
        if (emailField && !emailPattern.test(emailField.value)) {
          emailField.setCustomValidity("Please enter a valid email address.");
        } else {
          emailField.setCustomValidity("");
        }
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add("was-validated");
      },
      false
    );
  });
});
document.addEventListener("turbo:load", function () {
  $(".main-carousel").owlCarousel({
    items: 1,
    loop: true,
    nav: true,
    dots: false,
  });

  $(".thumbnail-cell").on("click", function () {
    var index = $(this).data("index");
    $(".main-carousel").trigger("to.owl.carousel", [index, 300, true]);
    $(".thumbnail-cell").removeClass("is-selected");
    $(this).addClass("is-selected");
  });

  $(".thumbnail-cell").first().addClass("is-selected");
});
