export function displayErrorMessages(
  container,
  isServiceChecked,
  isCountyChecked,
  hasCounties
) {
  const serviceErrorContainer = container.querySelector(
    ".services-error-container"
  );
  const countyErrorContainer = container.querySelector(
    ".county-error-container"
  );

  if (serviceErrorContainer) {
    serviceErrorContainer.textContent = isServiceChecked
      ? ""
      : "Please select at least one service and indicate if these services are offered for vacant properties, occupied properties or both.";
    serviceErrorContainer.style.color = isServiceChecked
      ? ""
      : "rgb(220, 53, 69)";
  }

  if (countyErrorContainer) {
    if (hasCounties) {
      countyErrorContainer.textContent = isCountyChecked
        ? ""
        : "Please select at least one county";
      countyErrorContainer.style.color = isCountyChecked
        ? ""
        : "rgb(220, 53, 69)";
    } else {
      countyErrorContainer.textContent =
        "No counties available for the selected metro. Please select different metro.";
      countyErrorContainer.style.color = "rgb(220, 53, 69)";
    }
  }
}
